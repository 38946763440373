<template>

<div class="meuble">
    <barre-navigation></barre-navigation>

    <div class="specialiteMeuble">
        <div class="blocMeuble">
            <div class="centrerMeuble">
                <div class="titreMeuble">
                    <h1> Meubles et faux plafonds décoratifs </h1>
                    <div class="ligneMeuble"></div>
                </div>
                <div class="descriptionMeuble">
                    <p> Les plaques de plâtre ont pour avantage de laisser libre cours à l’imagination et permettent de créer un univers unique et personnalisé.
                    Nous réalisons ainsi, des <span> meubles décoratifs </span>, des <span> étagères de rangement </span>, des niches pour exposer des objets ou créer des sources lumineuses. Dans des espaces pas très grands, ces éléments permettent aussi un gain de place important.
                    Nous réalisons aussi des <span> faux-plafonds </span> ou <span> plafonnier décoratifs </span> de formes très variables. Grâce à des bandes de plaques de plâtre, une parcelle est réalisée pour passer derrière les lumières et éclairer indirectement la pièce.
                    Ces éléments crées sur mesure peuvent être dessinés par nous-même ou par le client.
                    </p>
                </div>
            </div>
            <div class="photosMeuble">
                <img alt= "image-decoration-plafond" src="../assets/meuble1.png">
                <div class="phototv">
                    <img alt= "image-meubletv" src="../assets/meuble2.png">
                </div>
            </div>
            <div class="avapMeuble">
                <cartes-avant-apres-pages-details imgav="decoplafond-av.png" imgap="decoplafond-ap.png"></cartes-avant-apres-pages-details>
            </div>
        </div>
    </div>
</div>

</template>

<script>

    import BarreNavigation from '../components/BarreNavigation.vue'
    import CartesAvantApresPagesDetails from '../componentschild/CartesAvantApresPagesDetails.vue'

    export default {
        name: 'meuble',
        components: {
        BarreNavigation, CartesAvantApresPagesDetails
        }
    }

</script>

<style scoped>

    .meuble {

        overflow-x: hidden;

    }

    .specialiteMeuble {

        padding-right: 14rem;
        padding-left: 14rem;

    }

    .blocMeuble {

        padding: var(--padding-haut-bas-section);

    }

    .blocMeuble h1 {

        font-size: 36px;
        font-weight: bold;
        color: #333F50;
        padding-bottom: 40px;

    }


    .blocMeuble p {

        font-size: 16px;
        color: #333F50;

    }

    .centrerMeuble {

        display: flex;
        justify-content: space-evenly;

    }

    .titreMeuble h1 {

        font-size: 32.6px;

    }

    .ligneMeuble {

        background-color: #7C928F;
        height: 10px;
        width: 400px;

    }

    .descriptionMeuble {

        margin-left: 50px;
        font-size: 20px;
        
    }

    .descriptionMeuble p {

        line-height: var(--line-height-pagesdetails);

    }

    .descriptionMeuble span {

        font-weight: bold;

    }

    .photosMeuble {

        display: flex;
        justify-content: space-around;
        padding-top: 54px;

    }

    .avapMeuble {

        display: flex;
        justify-content: center;
        padding-top: 70px;

    }

    @media screen and (max-width: 1500px) {

    .centrerMeuble {

        padding-bottom: 30px;

    }

    .photosMeuble {

        padding-top: 0px;
        margin: 0 auto;

    }

    .phototv {

        display: none;

    }

    }

    @media screen and (max-width: 1400px) {

    .descriptionMeuble p {

        line-height: var(--line-height-medium);

    }

    .photosMeuble {

        height: 374px;
        width: 533px;

    } 

    }

    @media screen and (max-width: 1300px) {

    .titreMeuble h1 {

        font-size: 29.7px;

    }

    .ligneMeuble {
        
        width: 300px;

    }

    .avapMeuble {

        padding-top: 60px;

    }

    .avapMeuble img {

        height: 90%;
        width: 90%;

    }

    }

    @media screen and (max-width: 1110px) {

        .centrerMeuble {

            flex-direction: column;
            align-items: center;
            padding-bottom: 30px;

        }

        .titreMeuble h1 {

            font-size: 26.8px;
            text-align: center;

        }

        .ligneMeuble {

            width: 500px;
            height: 8px;

        }

        .descriptionMeuble {

            padding-top: 30px;
            display: flex;
            justify-content: center;
            margin-left: 0px;

        }

        .descriptionMeuble p {

            width: 500px;
            text-align: justify;

        }

        .photosMeuble {

            height: 284px;
            width: 420px;

        }

        .avapMeuble img {

            height: 85%;
            width: 85%;

        }

    }

    @media screen and (max-width: 1000px) {

        .centrerMeuble {

            padding-bottom: 50px;

        }

        .avapMeuble {

            justify-content: space-between;

        }

        .avapMeuble img {

            padding-top: 30px;

        }

        .avapMeuble img:nth-child(1) {

            height: 293px;
            width: 325px;

        }

        .avapMeuble img:nth-child(2) {

            height: 293px;
            width: 213px;

        }

    }

    @media screen and (max-width: 750px) {

        .specialiteMeuble {

            padding-right: 6rem;
            padding-left: 6rem;
            
        }

        .photosMeuble {

            height: 230px;
            width: 340px;

        }

        .avapMeuble {

            justify-content: center;
            
        }

    }

    @media screen and (min-width: 670px) and (max-width: 870px) {

        .blocMeuble {

            padding-top: 60px;

        }

    }

    @media screen and (max-width: 550px) {

        .specialiteMeuble {

            padding-right: 1rem;
            padding-left: 1rem;

        }

        .blocMeuble {

            padding-right: 1rem;
            padding-left: 1rem;

        }

        .titreMeuble h1 {

            font-size: 23px;
            text-align: center;
            padding-bottom: 30px;
            width: 300px;

        }

        .ligneMeuble {

            width: 300px;
            height: 6px;
            margin: 0 auto;

        }

        .descriptionMeuble p {

            width: 300px;
            font-size: 14px;

        }

        .photosMeuble {

            height: 197px;
            width: 280px;

        }

        .photosMeuble img {

            height: 100%;
            width: 100%;

        }

    }

</style>